import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout';
import Home from '../../../components/sections/home';
import Insurance from '../../../components/sections/insurance';
import PlanTypes from '../../../components/sections/plan-types';
import LicencedIn from '../../../components/licensed-in';
import Helmet from 'react-helmet';

export default () => {
  const [currentTab, setCurrentTab] = useState('VISION');
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState('insurance');
  const props = {
    currentTab,
    setCurrentTab,
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  const hasClass = function (el, className) {
    if (el.classList) return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  };

  const addClass = function (el, className) {
    if (!el) return;
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
  };

  const removeClass = (el, className) => {
    if (!el) return;

    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  };

  let supportPageOffset;

  let isCSS1Compat;

  try {
    supportPageOffset = window.pageXOffset;
    isCSS1Compat = ((document && document.compatMode) || '') === 'CSS1Compat';
  } catch (e) {}

  const sections = {
    VISION: []
  };
  useEffect(() => {
    const buttons = document.getElementById('plan-types-buttons');
    const content = document.getElementById('plan-types-content');

    const slider = document.getElementById('section-slider');
    console.log({
      currentPage,
      currentTab
    });
    window.onscroll = () => {
      if (typeof window !== undefined) {
        const top = supportPageOffset ? window && window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        const offsetTop = document.getElementById('plan-types')?.offsetTop;
        const sectionEnd = document.getElementById('plan-types-end')?.offsetTop - 216;
        const insruanceOffsetTop = document.getElementById('insurance')?.offsetTop;

        if (top >= offsetTop && top < sectionEnd) {
          addClass(buttons, 'plan-types-buttons-fixed-button');
          addClass(content, 'plan-types-content-y-down');
          currentTab == 'MEDICARE' ? currentPage !== 'medicare' && setCurrentPage('medicare') : currentPage !== 'insurance' && setCurrentPage('insurance');
        } else {
          removeClass(buttons, 'plan-types-buttons-fixed-button');
          removeClass(content, 'plan-types-content-y-down');
        }

        if (top >= offsetTop && top < sectionEnd - 240) {
          addClass(slider, 'section-slider-fixed');
        } else {
          removeClass(slider, 'section-slider-fixed');
        }

        const currentSections = sections[props.currentTab];

        let currentSection;

        for (let i in currentSections) {
          if (!currentSection) {
            const ele = document.getElementById(currentSections[Number(i)].to);
            if (ele) {
              const sTop = ele.offsetTop;
              if (top <= sTop - 90 && Number(i) > 0) {
                currentSection = currentSections[Number(i) - 1].to;
              }
            }
          }
        }
        if (!currentSection) {
          try {
            currentSection = sections[props.currentTab][sections[props.currentTab].length - 1].to;
          } catch (e) {}
        }

        for (let s of currentSections) {
          const sliderLink = document.getElementById('slider-link-' + s.to);

          if (s.to == currentSection) {
            addClass(sliderLink, 'section-slider-active-section');
          } else {
            removeClass(sliderLink, 'section-slider-active-section');
          }
        }
      }
    };
  });

  console.log('props', props);
  return (
    <Layout activePage={currentPage} {...props} id='insurance'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Author: Apollo Technology, a place to learn more and hire health insurance, get to know more about how to be secure and get the best health-insurance for you and your family '></meta>
        <title>Health Insurance</title>
      </Helmet>

      <PlanTypes sections={sections} {...props} />
      <LicencedIn {...props} />
    </Layout>
  );
};
